import React, { Component } from 'react';
import './App.css';
import Dash from './pages/dash';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      user: null
    }
  }

  componentDidMount() {
    const user = localStorage.getItem('user');
    if (user) {
      this.setState({ auth: true, user: JSON.parse(user) });
    }
  }

  authenticate = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({ auth: true, user });
  }

  setupCar = (car) => {
    const { user } = this.state;
    user.car = car;
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({ user });
  }

  updateCar = (car) => {
    const { user } = this.state;
    user.car = { ...user.car, ...car };
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({ user });
  }


  render() {
    const { auth, user } = this.state;

    return (
      <div className="App">
        <Dash user={user} updateCar={this.updateCar} />
      </div>
    );
  }
}

export default App;
