import React, { Component } from "react";

import garage from "../assets/images/GarageBlur.png";
import chatBG from "../assets/images/chatBG.png";
import JoeIcon from "../assets/images/JoeIcon.png";
import JoeTypingIcon from "../assets/images/JoeTypingIcon.png";
import { withEarthoOne } from "@eartho/one-client-react";

const ACCESS_POINTS = {
    APP: "JxDxKeZ4fbemDsk3J3mR",
    TRIAL: "bft5lOIuqtFTTVqGZ7ig",
    TOKENS100: "vJYEjulXLRjHwRtVaKeI",
    INSTALL: "dCEaWQBCQ4DQHyePTR93",
    NOTIFICATIONS: "yzb92lqMzzOL9WKPTu7p",
};

const printSpeed = 60;
var printMessageInterval = null;

const trialDash = {
    id: "5f9f1b9b0b9b4c0001b5b0b5",
    account: {
        uid: "5f9f1b9b0b9b4c0001b5b0b5",
        access: ["iQpG4KRUcWlol52aSQ9J"],
        displayName: "John",
        email: "intern@joemechanic.app",
        firstName: "John",
        lastName: "Doe",
        photoURL: "",
        uid: "KVcQlnbo4gNyPln2avM6GINnJ5m1",
    },
    tokens: 0,
    vehicleChats: {
        "5f9f1b9b0b9b4c0001b5b0b5": {
            id: "5f9f1b9b0b9b4c0001b5b0b5",
            name: "2019 Honda Civic",
            summary:
                "4 door sedan, 2.0L engine, 4 cylinder, 4 speed automatic transmission, 4 wheel drive, 4 wheel ABS, 4 wheel disc brakes, 4 wheel steering, 4 wheel traction control",
            vehicleId: "5f9f1b9b0b9b4c0001b5b0b5",
            messages: [],
        },
    },
    generalChats: {
        Trial: {
            id: "5f9f1b9b0b9b4c0001b5b0b5",
            name: "General Chat",
            summary: "Shop Talk. Chat about issues, trivia, or questions about cars, trucks, or any other vehicle .",
        },
    },
    vehicleGarage: {
        "5f9f1b9b0b9b4c0001b5b0b5": {
            id: "5f9f1b9b0b9b4c0001b5b0b5",
            name: "Vehicle Garage",
            vehicles: [
                {
                    id: "5f9f1b9b0b9b4c0001b5b0b5",
                    make: "Honda",
                    model: "Civic",
                    year: 2019,
                    vin: "1HGCR2F3XFA027534",
                    image: "",
                    mileage: 15000,
                    fuelType: "Gasoline",
                    fuelLevel: 0.5,
                    fuelEconomy: 0.5,
                    batteryLevel: 0.5,
                    batteryHealth: 0.5,
                    oilLife: 0.5,
                    tirePressure: 0.5,
                    tireHealth: 0.5,
                    brakeHealth: 0.5,
                    engineCoolant: 0.5,
                    engineHealth: 0.5,
                    transmissionHealth: 0.5,
                    transmissionFluid: 0.5,
                    airFilter: 0.5,
                    cabinFilter: 0.5,
                    airFilter: 0.5,
                    cabinFilter: 0.5,
                    fuelFilter: 0.5,
                    sparkPlugs: 0.5,
                    batteryCables: 0.5,
                    brakePads: 0.5,
                    brakeRotors: 0.5,
                    brakeFluid: 0.5,
                    coolantLevel: 0.5,
                    coolantHealth: 0.5,
                    washerFluid: 0.5,
                    wiperBlades: 0.5,
                    tireTread: 0.5,
                    tirePressure: 0.5,
                    tireHealth: 0.5,
                    brakeHealth: 0.5,
                    engineCoolant: 0.5,
                    engineHealth: 0.5,
                    transmissionHealth: 0.5,
                    transmissionFluid: 0.5,
                    airFilter: 0.5,
                    cabinFilter: 0.5,
                    fuelFilter: 0.5,
                    sparkPlugs: 0.5,
                    batteryCables: 0.5,
                    brakePads: 0.5,
                    brakeRotors: 0.5,
                    brakeFluid: 0.5,
                    coolantLevel: 0.5,
                    coolantHealth: 0.5,
                    washerFluid: 0.5,
                    wiperBlades: 0.5,
                    tireTread: 0.5,
                    tirePressure: 0.5,
                    tireHealth: 0.5,
                    brakeHealth: 0.5,
                    engineCoolant: 0.5,
                    engineHealth: 0.5,
                    transmissionHealth: 0.5,
                    transmissionFluid: 0.5,
                    airFilter: 0.5,
                    cabinFilter: 0.5,
                    fuelFilter: 0.5,
                    sparkPlugs: 0.5,
                    batteryCables: 0.5,
                    brakePads: 0.5,
                    brakeRotors: 0.5,
                    brakeFluid: 0.5,
                    coolantLevel: 0.5,
                    coolantHealth: 0.5,
                    washerFluid: 0.5,
                    wiperBlades: 0.5,
                    tireTread: 0.5,
                    tirePressure: 0.5,
                    tireHealth: 0.5,
                    brakeHealth: 0.5,
                    engineCoolant: 0.5,
                    engineHealth: 0.5,
                    transmissionHealth: 0.5,
                    transmissionFluid: 0.5,
                    airFilter: 0.5,
                    cabinFilter: 0.5,
                    fuelFilter: 0.5,
                    sparkPlugs: 0.5,
                    batteryCables: 0.5,
                    brakePads: 0,
                },
                {
                    id: "5f9f1b9b0b9b4c0001b5b0b6",
                    make: "Toyota",
                    model: "Camry",
                    year: 2018,
                    vin: "1HGCR2F3XFA027534",
                    image: "",
                    mileage: 15000,
                    fuelType: "Gasoline",
                    fuelLevel: 0.5,
                    fuelEconomy: 0.5,
                    batteryLevel: 0.5,
                    batteryHealth: 0.5,
                    oilLife: 0.5,
                },
            ],
        },
    },
};

let loadInterval = null;
let confirmationInterval = null;

let deferredPrompt;

class Dash extends Component {
    constructor(props) {
        super(props);
        const todayString = new Date().toDateString();
        const generalChats = {};
        generalChats[todayString] = {

            name: "General Chat",
            summary: "Shop Talk. Chat about issues, tips, or questions about cars, trucks, or any other vehicle.",

            messages: {},
        };
        //check that pwa was installed, especially on ios
        const iosInstalled = window.matchMedia("(display-mode: standalone)").matches;
        this.state = {
            userRecord: {},
            accountFetched: false,
            trialRedeemed: true,
            waitingForConfirmation: false,
            generalChats: generalChats,
            vehicleChats: {},
            currentGeneralChatId: todayString,
            currentVehicleChatId: todayString,
            vehicleChatting: false,
            fetching: false,
            tokens: 0,
            message: "",
            todayString: todayString,
            dayString: todayString,
            firstDay: false,
            newDay: false,
            chatOpen: false,
            messagesOpen: false,
            garageOpen: false,
            tokenStoreOpen: false,
            loggedOut: false,
            currentResponse: "",
            fullResponse: "",
            doneResponding: true,
            desiredSkillLevel: 0,
            iosInstalled: iosInstalled,
            versionModalOpen: false,
            profileModalOpen: false,
            upgradeAvailable: false,
        };

        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
        });
    }

    handleRedirectAccess = async () => {
        let accessApplied = false;
        try {
            const res = await this.props.eartho.handleRedirectCallback().then();
            if (res) {
                console.log("token bought", res);
                const confirmationCode = res.appState.waitingForConfirmation;
                const earthoUser = await this.props.eartho.getUser();
                const user = earthoUser.user;
                if (user) {
                    window.db.get("users/" + user.uid).once((userRecord) => {
                        if (userRecord) {
                            if (confirmationCode === ACCESS_POINTS.TOKENS100) {
                                const existingToken = userRecord.tokens || 0;
                                const newBalance = existingToken + 100;
                                window.db.get("users/" + user.uid).get('tokens').put(newBalance).then(() => {
                                    console.log("tokens added");

                                    window.db.get("users/" + user.uid).get('transactions').set(res).then(() => {
                                        setTimeout(() => {
                                            window.location.href = "/";
                                        }, 1000);
                                    });
                                });
                            } else {
                                window.location.href = "/";
                            }
                        }
                    })
                }
            }
        } catch (err) {
            console.log(accessApplied, err);
            if (!accessApplied) {
                setTimeout(this.handleRedirectAccess, 1000);
            }
        }
    }

    componentDidMount() {
        const { isLoading } = this.props.eartho;
        if (window.location.href.includes("?")) {
            this.handleRedirectAccess();
        } else if (isLoading) {
            this.listenForConnection();
        } else {
            this.loadDash();
        }

        window.addEventListener("message", (e) => {
            if (e.data && e.data === 'swUpdated') {
                this.setState({ upgradeAvailable: true })
            }
        });
    }

    addHomescreen = (e) => {
        // Show the prompt
        // Wait for the user to respond to the prompt
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    this.setState({ waitingForConfirmation: ACCESS_POINTS.INSTALL }, this.listenForPopupConfirmation);
                    deferredPrompt = null;
                }
            });
        }
    };

    activateNotifications = () => {
        Notification.requestPermission().then((result) => {
            if (result === "granted") {
                this.setState({ waitingForConfirmation: ACCESS_POINTS.NOTIFICATIONS }, this.listenForPopupConfirmation);
            }
        });
    };

    loadDash = async () => {
        const earthoUser = await this.props.eartho.getUser();
        const user = earthoUser.user;
        if (user) {
            window.db.get("users/" + user.uid).put({
                lastLogin: new Date().getTime(),
            });

            window.db.get("users/" + user.uid).once((userRecord) => {
                if (userRecord) {
                    if (user.access && user.access.includes(ACCESS_POINTS.APP) && !userRecord.connected) {
                        let trialRedeemed = user.access.includes(ACCESS_POINTS.TRIAL);
                        window.db.get("users/" + user.uid).put({
                            connected: true,
                            connectedAt: new Date().getTime(),
                            trialRedeemed: trialRedeemed,
                        });
                        this.setState({ trialRedeemed: trialRedeemed, accountFetched: true });
                    } else if (user.access && user.access.includes(ACCESS_POINTS.APP) && userRecord.connected) {
                        this.setState({ trialRedeemed: user.access.includes(ACCESS_POINTS.TRIAL) });
                    } else {
                        this.setState({ trialRedeemed: user.access.includes(ACCESS_POINTS.TRIAL) });
                    }
                    window.db
                        .get("users/" + user.uid)
                        .get("tokens")
                        .on((tks) => {
                            if (tks) this.setState({ tokens: tks });
                        });
                    const { todayString } = this.state;
                    window.db
                        .get("users/" + user.uid)
                        .get("generalChats")
                        .get(todayString)
                        .once(chat => {
                            const newGeneralChats = this.state.generalChats;
                            let newDay = false;
                            if (!chat) {
                                window.db
                                    .get("users/" + user.uid)
                                    .get("generalChats")
                                    .get(todayString)
                                    .put({
                                        name: "General Chat",
                                        summary: "Shop Talk. Chat about issues, tips, or questions about cars, trucks, or any other vehicle.",
                                    });

                                newGeneralChats[todayString] = {
                                    ...this.state.generalChats[todayString],
                                    name: "General Chat",
                                    summary: "Shop Talk. Chat about issues, tips, or questions about cars, trucks, or any other vehicle.",
                                };
                                newDay = true;
                            } else {
                                if (!chat.messages) newDay = true;
                                newGeneralChats[todayString] = {
                                    ...this.state.generalChats[todayString],
                                    name: chat.name,
                                    summary: chat.summary,
                                };
                            }
                            this.setState({ generalChats: newGeneralChats, currentGeneralChatId: todayString, newDay: newDay, accountFetched: true });
                        })
                    window.db
                        .get("users/" + user.uid)
                        .get("generalChats")
                        .once((chat) => {
                            if (chat) {
                                this.setState({ firstDay: Object.keys(chat).length <= 2 });
                            }
                        });
                    window.db
                        .get("users/" + user.uid)
                        .get("generalChats")
                        .get(todayString)
                        .get("messages")
                        .map()
                        .on((message, id) => {
                            if (message) {
                                const { generalChats, todayString } = this.state;
                                if (generalChats[todayString] && generalChats[todayString].messages && !generalChats[todayString].messages[message.time]) {
                                    const newGeneralChats = generalChats;
                                    newGeneralChats[todayString].messages[message.time] = message;
                                    this.setState({ generalChats: newGeneralChats });
                                }
                                this.scrollToBottom();
                            }
                        });
                    this.setState({ userRecord: userRecord });
                }
            })
        } else {
            this.logout();
        }
    };

    loadDemoDash = () => {
        this.setState({ accountFetched: true, tokens: trialDash.tokens, generalChats: trialDash.generalChats, currentGeneralChatId: "Trial" });
    };

    listenForConnection = (reload) => {
        loadInterval = setInterval(() => {
            const { isConnected, isLoading, user } = this.props.eartho;
            if (isConnected && !isLoading && user && user.user && user.user.access) {
                clearInterval(loadInterval);
                if (reload) window.location.reload();
                else this.loadDash();
            } else if (!isLoading) {
                clearInterval(loadInterval);
                this.loadDemoDash();
            }
        }, 100);
    };

    listenForRedirectConfirmation = () => {
        const { connectWithRedirect } = this.props.eartho;
        const { waitingForConfirmation } = this.state;
        connectWithRedirect({ access_id: waitingForConfirmation, appState: { waitingForConfirmation: waitingForConfirmation, desiredSkillLevel: this.state.desiredSkillLevel } });
    };

    listenForPopupConfirmation = () => {
        confirmationInterval = setInterval(() => {
            const { waitingForConfirmation } = this.state;
            const { isLoading } = this.props.eartho;
            const user = this.props.eartho.user ? this.props.eartho.user.user : false;
            if (!isLoading && waitingForConfirmation && user && user.access && user.access.includes(waitingForConfirmation)) {
                clearInterval(confirmationInterval);
                window.db.get('users/' + user.uid).once((userRecord) => {
                    {
                        if (waitingForConfirmation === ACCESS_POINTS.TRIAL) {
                            //10 tokens as thanks for sharing your mechanic level with Joe and get you started chatting.
                            window.db.get('users/' + user.uid).get("tokens").put(10).then(() => {
                                window.db.get('users/' + user.uid).get("skillLevel").put(this.state.desiredSkillLevel).then(() => {
                                    setTimeout(() => {
                                        window.location.href = "/";
                                    }, 1000);
                                });
                            });
                        } else if (waitingForConfirmation === ACCESS_POINTS.INSTALL && !user.access.includes(ACCESS_POINTS.INSTALL)) {
                            const currentTokens = userRecord.tokens;
                            const updatedBalance = currentTokens + 5;
                            window.db.get("users/" + user.uid).get('tokens').put(
                                updatedBalance
                            ).then(() => {
                                console.log("tokens updated");
                                this.setState({ waitingForConfirmation: false });
                            });
                        } else if (waitingForConfirmation === ACCESS_POINTS.NOTIFICATIONS && !user.access.includes(ACCESS_POINTS.NOTIFICATIONS)) {
                            const currentTokens = userRecord.tokens;
                            const updatedBalance = currentTokens + 10;
                            window.db.get("users/" + user.uid).get("tokens").put(updatedBalance).then((e) => {
                                console.log("tokens updated");
                                this.setState({ waitingForConfirmation: false });
                            });
                        }
                    }
                })
            } else if (!isLoading && waitingForConfirmation && user && user.access && !user.access.includes(waitingForConfirmation)) {
                //cancelled
                clearInterval(confirmationInterval);
                if (waitingForConfirmation === ACCESS_POINTS.TRIAL) {
                    this.setState({ waitingForConfirmation: false, desiredSkillLevel: 0 });
                }
            }
        }, 100);
        const { connectWithPopup } = this.props.eartho;
        connectWithPopup({ access_id: this.state.waitingForConfirmation });
    }

    scrollToBottom = () => {
        const chat = document.querySelector(".chatMessages");
        chat && (chat.scrollTop = chat.scrollHeight);
    };

    scrollResponseToBottom = () => {
        const chat = document.querySelector(".responseText");
        chat && (chat.scrollTop = chat.scrollHeight);
    };

    sendMessage = () => {
        const user = this.props.eartho.user ? this.props.eartho.user.user : false;
        if (user) {
            let { message, todayString } = this.state;
            message = message.trim();
            if (message === "") {
                this.setState({ message: "" });
                return;
            }
            this.setState({ fetching: true, tokenStoreOpen: false });
            if (!(message.endsWith(".") || message.endsWith("!") || message.endsWith("?"))) message += ".";
            window.db
                .get("users/" + user.uid)
                .get("generalChats")
                .get(todayString)
                .get("messages")
                .set({
                    user: true,
                    text: message,
                    time: new Date().toISOString(),
                })
                .then(() => {
                    this.setState({ message: "" }, this.generateResponse);
                });
        }
    };

    sendReply = (reply) => {
        const { todayString } = this.state;
        const user = this.props.eartho.user ? this.props.eartho.user.user : false;
        if (user) {
            window.db
                .get("users/" + user.uid)
                .get("generalChats")
                .get(todayString)
                .get("messages")
                .set({
                    user: false,
                    text: reply,
                    time: new Date().toISOString(),
                })
                .then(() => {
                    this.setState({ currentResponse: "" });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    generateResponse = () => {
        const user = this.props.eartho.user ? this.props.eartho.user.user : false;
        if (user) {
            const prompt = this.composePrompt();
            //post prompt to https://us-west2-joe-mechanic.cloudfunctions.net/chat
            fetch("https://us-west2-joe-mechanic-20xx.cloudfunctions.net/chat", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ prompt }),
                //mode: "cors",
            })
                .then((res) => res.json())
                .then((res) => {
                    let reply = res.data;
                    this.setState({
                        currentResponse: "",
                        fullResponse: reply,
                        doneResponding: false,
                        fetching: false,
                    });
                    window.db.get("users/" + user.uid).once((userRecord) => {
                        if (userRecord) {
                            window.db
                                .get("users/" + user.uid)
                                .get("tokens")
                                .put(userRecord.tokens - 1);
                        }
                    })

                    printMessageInterval = setInterval(this.printCurrentMessage, printSpeed);
                })
                .catch((err) => {
                    this.setState({ fetching: false });
                });
        }
    };

    printCurrentMessage = () => {
        const { currentResponse, fullResponse } = this.state;
        if (currentResponse === fullResponse) {
            clearInterval(printMessageInterval);
            this.setState({ doneResponding: true }, () => {
                this.sendReply(fullResponse);
            });
            return;
        }
        this.setState({ currentResponse: fullResponse.substring(0, currentResponse.length + 1) }, this.scrollResponseToBottom);
    };

    composePrompt = () => {
        const { generalChats, userRecord, todayString, vehicleChatting, currentVehicleChatId, vehicleChats, currentGeneralChatId } = this.state;
        const user = this.props.eartho.user ? this.props.eartho.user.user : false;
        const currentChat = vehicleChatting ? vehicleChats[currentVehicleChatId] : generalChats[currentGeneralChatId] || {};
        let todaysMessages = Object.keys(currentChat.messages).sort((a, b) => {
            // time is in format "hh:mm:ss a" from earlier to later
            const timeA = new Date(currentChat.messages[a].time);
            const timeB = new Date(currentChat.messages[b].time);

            if (isNaN(timeA.getTime()) || isNaN(timeB.getTime())) {
                // handle invalid date string
                console.error("Invalid date string: ", currentChat.messages[a].time, currentChat.messages[b].time);
                return 0;
            }

            const isoTimeA = timeA.toISOString();
            const isoTimeB = timeB.toISOString();

            return isoTimeA.localeCompare(isoTimeB);
        });
        const messages = [];
        let prompt = `You are a proud vehicle mechanic expert named Joe, who hardly ever recommends other mechanics, here to chat about vehicle upkeep tips, basic maintenance tasks, or complex repair issues. You are currently in chat room "${generalChats[todayString].name
            }" chatting with a person named ${user.firstName} ${userRecord.skillLevel == 1
                ? ", who is a beginner who wanting to learn"
                : userRecord.skillLevel == 2
                    ? ", who is an intermediate who has changed their own oil before"
                    : userRecord.skillLevel == 3
                        ? ", who is an advanced mechanic that can fix anything"
                        : userRecord.skillLevel == 4
                            ? ", who is an expert mechanic that knows it all"
                            : ""
            }, about ${generalChats[todayString].summary}`;
        messages.push({
            role: "system",
            content: prompt,
        });
        let charCount = 0;
        const messageLog = []
        todaysMessages
            .forEach((messageKey) => {
                const message = currentChat.messages[messageKey];
                if (charCount > 1500) return;
                charCount += message.text.length;
                messageLog.push({
                    role: message.user ? "user" : "assistant",
                    content: message.text,
                })
            });

        let longMessage = false;
        if (charCount >= 1500) {
            longMessage = true;
        }

        if (longMessage) {
            messages.push({
                role: "system",
                content: "Respond contextually to the latest inquiry, from this snippet of at most the last 2000 characters of the current chat."
            });
        }
        messages.push(...messageLog);
        return messages;
    };

    goToPreviousDay = () => {
        const { dayString, messages } = this.state;
        const previousDay = new Date(dayString);
        previousDay.setDate(previousDay.getDate() - 1);
        const previousDayString = previousDay.toDateString();
        const previousDayMessages = localStorage.getItem("messages-" + previousDayString);
        this.setState(
            {
                dayString: previousDayString,
                messages: {
                    ...messages,
                    [previousDayString]: previousDayMessages ? JSON.parse(previousDayMessages) : [],
                },
            },
            this.scrollToBottom
        );
    };

    goToNextDay = () => {
        const { todayString, dayString, messages } = this.state;
        if (todayString === dayString) return;
        const nextDay = new Date(dayString);
        nextDay.setDate(nextDay.getDate() + 1);
        const nextDayString = nextDay.toDateString();
        const nextDayMessages = localStorage.getItem("messages-" + nextDayString);
        this.setState(
            {
                dayString: nextDayString,
                messages: {
                    ...messages,
                    [nextDayString]: nextDayMessages ? JSON.parse(nextDayMessages) : [],
                },
            },
            this.scrollToBottom
        );
    };

    logout = async () => {
        const { dayString } = this.state;
        const { logout } = this.props.eartho;
        await logout();
        const clearedMessages = {};
        clearedMessages[dayString] = [];
        this.setState({ messages: clearedMessages, loggedOut: true });
        window.location.reload();
    }

    accountClick = async () => {
        const { loggedOut } = this.state;
        const { isLoading, isConnected, connectWithPopup } = this.props.eartho;
        if (isLoading) {
            return;
        } else if (isConnected && !loggedOut) {
            this.logout();
        } else {
            connectWithPopup({ access_id: ACCESS_POINTS.APP });
            this.listenForConnection(true);
        }
    };

    openDash = async () => {
        const { trialRedeemed, tokens, vehicleChatting, generalChats, vehicleChats, currentGeneralChatId, currentVehicleChatId, doneResponding, loggedOut } = this.state;
        const { isConnected } = this.props.eartho;
        const currentChat = vehicleChatting ? vehicleChats[currentVehicleChatId] : generalChats[currentGeneralChatId] || {};
        let messages = currentChat.messages ? Object.keys(currentChat.messages) : [];

        const chatDisabled = !doneResponding || tokens == 0;
        const trialAvailable = !trialRedeemed && tokens == 0;
        const connectedToEartho = isConnected && !loggedOut;
        if (!chatDisabled && !trialAvailable && connectedToEartho && messages.length == 0) {
            if (this.state.firstDay && this.state.newDay) {
                const reply =
                    "Hi, I'm Joe a vehicle maintenance and repair expert, I'm here to assist you with any questions you may have regarding your or anyone's vehicle. Whether you need help with basic maintenance tasks or have a complex repair issue, I'm here to help. Let's get started, what vehicle do you currently drive?";
                this.setState({
                    currentResponse: "",
                    fullResponse: reply,
                    doneResponding: false,
                    fetching: false,
                });
                printMessageInterval = setInterval(this.printCurrentMessage, printSpeed);
            } else if (this.state.newDay) {
                const reply = "Hey, welcome back! How's your vehicle doing today?";
                this.setState({
                    currentResponse: "",
                    fullResponse: reply,
                    doneResponding: false,
                    fetching: false,
                });
                printMessageInterval = setInterval(this.printCurrentMessage, printSpeed);
            }
        }
        this.setState({ chatOpen: true, messagesOpen: true, tokenStoreOpen: false });
        this.scrollToBottom();
    };

    redeemTrial = async (skillLevel) => {
        this.setState({ desiredSkillLevel: skillLevel, waitingForConfirmation: ACCESS_POINTS.TRIAL }, this.listenForPopupConfirmation);
    };

    purchaseTokens = async () => {
        this.setState({ waitingForConfirmation: ACCESS_POINTS.TOKENS100 }, this.listenForRedirectConfirmation);
    };

    toggleServiceDesk = () => {
        this.setState({ tokenStoreOpen: !this.state.tokenStoreOpen });
    };

    share = () => {
        const shareData = {
            title: "Chat with Joe Mechanic",
            text: "Get tips and advice from an AI vehicle maintenance and repair expert",
            url: "https://joemechanic.app",
        };
        navigator.share(shareData);
    };

    redeemIOSInstall = async () => {
        this.setState({ waitingForConfirmation: ACCESS_POINTS.IOS }, this.listenPopupForConfirmation);
    };

    updateSkillLevel = (skillLevel) => {
        const user = this.props.eartho.user ? this.props.eartho.user.user : false;
        window.db.get('users/' + user.uid).get("skillLevel").put(skillLevel).then(() => {
            this.setState({ skillLevel });
        });
    }

    render() {
        const {
            trialRedeemed,
            accountFetched,
            chatOpen,
            tokens,
            garageOpen,
            vehicleChatting,
            generalChats,
            vehicleChats,
            currentGeneralChatId,
            currentVehicleChatId,
            messagesOpen,
            tokenStoreOpen,
            fetching,
            message,
            currentResponse,
            fullResponse,
            doneResponding,
            loggedOut,
            waitingForConfirmation,
            iosInstalled,
            versionModalOpen,
            profileModalOpen,
            userRecord,
            upgradeAvailable
        } = this.state;
        const { isLoading, isConnected, error } = this.props.eartho;
        let earthoUser = this.props.eartho.user && this.props.eartho.user.user ? this.props.eartho.user.user : {};
        const currentChat = vehicleChatting ? vehicleChats[currentVehicleChatId] : generalChats[currentGeneralChatId] || {};
        const canNotify = ("Notification" in window);
        let messages = currentChat.messages
            ? Object.keys(currentChat.messages).sort((a, b) => {
                // time is in format "hh:mm:ss a" from earlier to later
                const timeA = new Date(currentChat.messages[a].time);
                const timeB = new Date(currentChat.messages[b].time);

                if (isNaN(timeA.getTime()) || isNaN(timeB.getTime())) {
                    // handle invalid date string
                    console.error("Invalid date string: ", currentChat.messages[a].time, currentChat.messages[b].time);
                    return 0;
                }

                const isoTimeA = timeA.toISOString();
                const isoTimeB = timeB.toISOString();

                return isoTimeA.localeCompare(isoTimeB);
            })
            : [];
        const chatDisabled = !doneResponding || tokens == 0;
        const trialAvailable = !trialRedeemed && tokens == 0;
        const connectedToEartho = isConnected && !loggedOut;
        return (
            <div className="page" style={{ backgroundImage: `url(${garage})` }}>
                <div className="modal">
                    <h5 className="modalTopHeader" onClick={() => this.setState({ versionModalOpen: true })}>
                        <div className="availabilityIndicator online"></div>JoeGPT <span className="linkButton warn ml5">v1{upgradeAvailable && <span className="sup">update available</span>}</span>
                    </h5>
                    <div className="modelBottomContent">
                        <div className="shadowText">
                            <h1>Joe Mechanic</h1>
                        </div>
                        <button className={`modalButton ${(!accountFetched && !connectedToEartho) || isLoading ? "disabled" : ""}`} onClick={this.openDash}>
                            Let's talk shop
                        </button>
                        <div className="earthoLogin">
                            {isLoading ? null : connectedToEartho ? <h4>Connected as <span className="linkButton" onClick={() => {
                                this.setState({ profileModalOpen: true });
                            }}>{earthoUser.displayName}</span></h4> : null}
                            <button className={`linkButton mt10 ${!isLoading && connectedToEartho ? "danger" : ""} `} onClick={this.accountClick}>
                                {isLoading ? "loading" : connectedToEartho ? "logout" : "disconnected"}
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`modal chatWindow ${chatOpen ? "" : "closed"}`}
                    style={{
                        backgroundImage: `url(${chatBG})`,
                    }}
                >
                    <div className="modalTopHeader chatTopHeader">
                        <button
                            className="flex-ac"
                            onClick={() => {
                                this.setState({ chatOpen: false });
                            }}
                        >
                            <div className="triangle mr10"></div>Exit
                        </button>
                        <div className="flex-ac">
                            Credits Remaining:{" "}
                            <div className={`credits ${!isConnected || trialAvailable ? "disabled" : ""}`} onClick={this.toggleServiceDesk}>
                                <strong>{tokens}</strong>{" "}
                            </div>
                        </div>
                    </div>
                    <div className="chatHeader">
                        <h4 style={{ marginBottom: "2vh" }}>{currentChat.name}</h4>
                        <p>{currentChat.summary}</p>
                    </div>
                    <div className="joeChatContainer">
                        <div className="joeIcon">
                            <img src={currentResponse != "" && !doneResponding ? JoeTypingIcon : JoeIcon} />
                        </div>
                        <div className="joeChat">
                            {/* !messages[dayString] || messages[dayString].length == 0 && <div className="flex-ac">
                                <div className="warning">!</div>
                                <div>
                                    <p>I am not perfect and still learning.</p>
                                    <p className="small">I May occasionally generate incorrect information.</p>
                                    <p className="small">Take extreme precaution when attempting any repairs yourself!</p>
                                </div>
                            </div> */}
                            {connectedToEartho ? (
                                <div>
                                    {tokenStoreOpen ? (
                                        <div>
                                            <p className="mb10">Purchase Tokens</p>
                                            <div className="flex-ac">
                                                <button
                                                    className={`${isLoading ? "disabled" : ""} linkButton warn mr20  mb20`}
                                                    onClick={this.purchaseTokens}
                                                >
                                                    100 tokens for $1
                                                </button>

                                                <button className="linkButton  mb20" onClick={this.toggleServiceDesk}>
                                                    Close Service Desk
                                                </button>
                                            </div>
                                            <p className="small">
                                                <strong>IMPORTANT:</strong> Tokens are only available on the device on which they are purchased.
                                                <br />
                                                <i>Do not purchase when incognito</i>
                                            </p>
                                        </div>
                                    ) : trialAvailable ? (
                                        <div>
                                            <p>
                                                {isLoading || waitingForConfirmation
                                                    ? "Waiting for confirmation..."
                                                    : "Great to connect with you! Let's get you some tokens, on the house. To get our chat started, just answer the question below."}
                                            </p>
                                        </div>
                                    ) : (
                                        <div>
                                            {!fetching && !currentResponse && (
                                                <div>
                                                    {tokens === 0 && (
                                                        <div>
                                                            <p className="mb10">Looks like you are out of tokens, purchase more to keep chatting.</p>
                                                        </div>
                                                    )}
                                                    <div className="flex-ac">
                                                        {/* garageOpen ? (
                                                        <button
                                                            className="linkButton warn mr20"
                                                            onClick={() => {
                                                                this.setState({ garageOpen: false });
                                                            }}
                                                        >
                                                            Close Garage
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="linkButton mr20"
                                                            onClick={() => {
                                                                this.setState({ garageOpen: true });
                                                            }}
                                                        >
                                                            Open Garage
                                                        </button>
                                                    ) */}
                                                        {/* <button className="linkButton">Past Chats</button> */}
                                                        <button className="linkButton" onClick={this.toggleServiceDesk}>
                                                            Open Service Desk
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {fetching && (
                                                <p>
                                                    <i>thinking...</i>
                                                </p>
                                            )}
                                            {!fetching && currentResponse && <p className="responseText">{currentResponse}</p>}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <p className="mb10">Hi, I'm Joe, an AI mechanic. It looks like we are not connected. Please connect when you are ready to chat.</p>
                                    <button
                                        className="linkButton"
                                        onClick={() => {
                                            this.setState({ chatOpen: false }, this.accountClick);
                                        }}
                                    >
                                        Connect
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {connectedToEartho ? (
                        <div className="chatContainer">
                            {trialAvailable && (
                                <div className="trialQuestions">
                                    {!waitingForConfirmation && (
                                        <div className={`message car`}>
                                            <p className="text">What is your level of experience as a mechanic?</p>
                                            <p className="small mb10">Choose to redeem 10 free tokens.</p>
                                            <button onClick={() => this.redeemTrial(4)} className={`linkButton warn ${isLoading ? 'disabled' : ''}`}>
                                                Expert
                                            </button>
                                            <p className="small mb10">I know it all </p>
                                            <button onClick={() => this.redeemTrial(3)} className={`linkButton warn ${isLoading ? 'disabled' : ''}`}>
                                                Advanced
                                            </button>
                                            <p className="small mb10">I can fix anything</p>
                                            <button onClick={() => this.redeemTrial(2)} className={`linkButton warn ${isLoading ? 'disabled' : ''}`}>
                                                Intermediate{" "}
                                            </button>
                                            <p className="small mb10">I've changed my own oil</p>
                                            <button onClick={() => this.redeemTrial(1)} className={`linkButton warn ${isLoading ? 'disabled' : ''}`}>
                                                Beginner{" "}
                                            </button>
                                            <p className="small mb20">I want to learn</p>

                                            <p className="small">
                                                <strong>IMPORTANT:</strong> Tokens are only available on the device on which they are purchased.
                                                <br />
                                                <i>Do not purchase when incognito</i>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className={`tokenStoreUpsells ${!tokenStoreOpen ? "closed" : ""}`}>
                                {(deferredPrompt || iosInstalled) && <div className={`message car`}>
                                    <p>Add to Homescreen</p>
                                    <p className="small">Get 5 free tokens for installing Joe Mechanic to your homescreen.</p>
                                    <br />
                                    {earthoUser && earthoUser.access && !earthoUser.access.includes(ACCESS_POINTS.INSTALL) && iosInstalled ? <button className="linkButton gray" onClick={this.redeemIOSInstall}>Redeem</button> : earthoUser && earthoUser.access && earthoUser.access.includes(ACCESS_POINTS.INSTALL) ? (
                                        <button className="linkButton gray">Installed</button>
                                    ) : (
                                        <button className={`linkButton warn ${isLoading ? 'disabled' : ''}`} onClick={this.addHomescreen}>
                                            Add to homescreen
                                        </button>
                                    )}
                                </div>}

                                {canNotify && <div className={`message car`}>
                                    <p>Activate Notifications</p>
                                    <p className="small">Get 10 free token for activating notifications.</p>
                                    <br />
                                    {earthoUser && earthoUser.access && earthoUser.access.includes(ACCESS_POINTS.NOTIFICATIONS) ? (
                                        <button className="linkButton gray">Active</button>
                                    ) : (
                                        <button className={`linkButton warn ${isLoading ? 'disabled' : ''}`} onClick={this.activateNotifications}>
                                            Activate
                                        </button>
                                    )}
                                </div>}

                                <div className={`message car`}>
                                    <p>Share</p>
                                    <p className="small">Help add more features and expertise by sharing Joe Mechanic with friends.</p>
                                    <br />
                                    <button className="linkButton warn" onClick={this.share}>
                                        Share
                                    </button>
                                </div>
                            </div>
                            <div className={`chatMessages ${!messagesOpen || garageOpen || tokenStoreOpen || trialAvailable ? "closed" : ""}`}>
                                {messages.map((messageKey) => {
                                    const message = currentChat.messages[messageKey];
                                    const formattedTime = (new Date(message.time).toString() === 'Invalid Date') ? '' : new Date(message.time).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', second: '2-digit', hour12: true });

                                    return (
                                        <div key={`c-${currentGeneralChatId}-${messageKey}`} className={`message ${message.user ? "user" : "car"}`}>
                                            <p className="text">{message.text}</p>
                                            <p className="time">{formattedTime}</p>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={`inputContainer ${!messagesOpen || garageOpen || tokenStoreOpen ? "closed" : ""} ${chatDisabled ? "disabled" : ""} `}>
                                <div className="chatBox">
                                    <input
                                        type="text"
                                        value={message}
                                        placeholder={chatDisabled ? "Chat disabled" : "Type your message here..."}
                                        onChange={(e) => e.target.value.length < 160 && this.setState({ message: e.target.value })}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                this.sendMessage();
                                            }
                                        }}
                                    />
                                    {tokens > 0 ? (
                                        <button className={`sendButton`} onClick={this.sendMessage}>
                                            Send
                                        </button>
                                    ) : (
                                        <button className={`sendButton noTokens ${trialAvailable ? "disabled" : ""}`} onClick={this.toggleServiceDesk}>
                                            Buy Tokens
                                        </button>
                                    )}
                                </div>
                                <p className="chatDisclaimer small">Use responsibly, take precautions when attempting repairs.</p>
                            </div>
                        </div>
                    ) : (
                        <div className="previewContainer"></div>
                    )}
                </div>
                <div className={`modal versionModal ${!versionModalOpen ? 'closed' : ''}`}>

                    <h4 className="modalTopHeader linkButton warn" onClick={() => this.setState({ versionModalOpen: false })}>
                        <i className="las la-times"></i> Close
                    </h4>
                    <br />
                    <div className="modalContent">
                        <h3>Joe Mechanic</h3>
                        <h5>Version 1.07</h5>
                        {upgradeAvailable && <h5 style={{ width: 'max-content', margin: '0 auto', marginTop: 5, marginBottom: 5 }} className="linkButton warn" onClick={window.upgrade}>UPDATE</h5>}
                        <br />
                        <p>
                            Thanks for using Joe Mechanic. We are always looking to improve the app. If you have any suggestions or feedback, <a href="mailto:support@joemechanic.app">please let us know</a>.
                        </p>
                        <br />
                        <h4>Data & Privacy</h4>
                        <p>Conversations and authentication information is stored only in authorized devices. No data is stored on a central server.</p>
                        <br />
                        <h4>Connect Socially</h4>
                        <a style={{ textAlign: 'center' }} className="linkButton" href="https://www.facebook.com/people/Joe-Mechanic/100090934508706/" target="_blank">Follow on Facebook</a>
                        <br /><br />
                        <h4>Developed By</h4><h5>AI Industry Experts LLC</h5>
                        <br />
                    </div>
                </div>

                <div className={`modal profileModal ${!profileModalOpen ? 'closed' : ''}`}>

                    <div className="modalContent">
                        <img src={earthoUser.photoURL} className="photoURL" />
                        <h2>{earthoUser.firstName} {earthoUser.lastName}</h2>
                        <h5>Connected Since</h5><p>{userRecord.connectedAt ? new Date(userRecord.connectedAt).toDateString() : ''}</p>
                        <br />
                        <h5>Skill Level</h5>
                        <select className="skillSelect" value={userRecord.skillLevel} onChange={(e) => this.updateSkillLevel(e.target.value)}>
                            <option value="1">Beginner</option>
                            <option value="2">Intermediate</option>
                            <option value="3">Advanced</option>
                            <option value="4">Expert</option>
                        </select>
                        <br />
                    </div>
                    <br />
                    <h4 className="modalTopHeader linkButton" onClick={() => this.setState({ profileModalOpen: false })}>
                        <i className="las la-times"></i> Close
                    </h4>
                </div>
            </div>
        );
    }
}

export default withEarthoOne(Dash);
